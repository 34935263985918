const leftSideMenu = document.getElementById('left-side-menu');
const rightSideMenu = document.getElementById('right-side-menu');
const bannerContainer = document.getElementById('banner');
const pageHeader = document.getElementById('website-header');

document.addEventListener('DOMContentLoaded', () => {
    fetch('/content.json')
    .then((data) => data.json())
    .then((data) => {
        for (let i=0; i < (data.left || []).length; i++) {
            leftSideMenu.append(new SideMenuItem(data.left[i]));
        }
        for (let i=0; i < (data.right || []).length; i++) {
            rightSideMenu.append(new SideMenuItem(data.right[i]));
        }
        for (let i=0; i < (data.banner || []).length; i++) {
            if (i == 1) {
                bannerContainer.append(clonePageHeader());
            }
            bannerContainer.append(new BannerItem(data.banner[i]));
        }
        bannerContainer.append(new BannerItem(data.banner[0]));
        bannerContainer.append(clonePageHeader());
        bannerContainer.append(new BannerItem(data.banner[1]));
    });
});

function clonePageHeader() {
    const r = pageHeader.cloneNode(true);
    r.classList.remove('hidden');
    return r;
}